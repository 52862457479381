import clsx from "clsx";

export function TypographyH2(props: { className?: string; children: any }) {
  return (
    <h2
      className={clsx(
        "scroll-m-20pb-2 text-2xl font-semibold tracking-tight transition-colors first:mt-0",
        props.className
      )}
    >
      {props.children}
    </h2>
  );
}
